import React, { useState, useEffect } from "react";

function useGitHubCommits(repo, sliceStart, sliceEnd) {
    const [commits, setCommits] = useState([]);
    // if dos not show commits, got to this link to check this token Expire date https://github.com/settings/personal-access-tokens/1724671
    const accessToken = process.env.REACT_APP_GITHUB_API_TOKEN;
    useEffect(() => {
        const url = `https://api.github.com/repos/${repo}/commits`;
        const config = {
            headers: {
                Authorization: `token ${accessToken}`,
            },
        };
        fetch(url, config)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // setCommits(data.slice(0, 5));
                setCommits(data.slice(sliceStart, sliceEnd));
            })
            .catch((error) => {
                console.error(error);
            });
    }, [repo, sliceStart, sliceEnd, accessToken]);
    return commits;
}

function CommitList({ repo, sliceStart, sliceEnd }) {
    const commits = useGitHubCommits(repo, sliceStart, sliceEnd);
    return (
        <div className='text-xs italic font-thin'>
            {commits.map((commit) => (
                <p key={commit.sha} >
                    <b> {new Date(commit.commit.author.date).toLocaleString('en-GB', { timeZone: 'UTC' })} : </b>
                    {commit.commit.message}
                </p>
            ))}
        </div>
    );
}

function LastCommitDate({ repo, sliceStart, sliceEnd }) {
    const commits = useGitHubCommits(repo, sliceStart, sliceEnd);

    // Get the date of the last commit (most recent commit)
    // const lastCommitDate = commits.length > 0 ? new Date(commits[0].commit.author.date).toLocaleDateString() : "No commits";

    const lastCommitDate = commits.length > 0 ? formatDate(commits[0].commit.author.date) : "No commits";
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const year = date.getFullYear();
        
        // Array of month names
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        
        // Get the month name
        const month = monthNames[date.getMonth()];
        
        // Return the formatted date as "09-Sept-2024"
        return `${day < 10 ? '0' + day : day}-${month}-${year}`;
    }


    return (          
            <p className="text-left "> <span className="font-semibold ">Last github commit changed on : </span> <small>{lastCommitDate}</small> </p>
       
    );
}



export { CommitList, LastCommitDate };